.dashboardContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.dashboardContent {
    display: flex;
    height: calc(100vh - 80px);
    margin-top: 80px;
    position: relative;
    overflow: hidden;
}

.dashboardSidebarContainer {
    width: 250px;
    background-color: #f5f5f5;
    height: 100%;
    overflow-y: auto;
    transition: transform 0.3s ease;
    position: fixed;
    left: 0;
    top: 80px;
    bottom: 0;
    z-index: 10;
}

.dashboardSidebarHidden {
    transform: translateX(-100%);
}

.dashboardMainContentWrapper {
    flex: 1;
    overflow: hidden;
    margin-left: 235px;
    transition: margin-left 0.3s ease, margin-right 0.3s ease;
}

.dashboardMainContent {
    padding: 20px;
    height: 96%;
    overflow-y: auto;
    max-width: 100%; /* Ensure content doesn't overflow */

}

/*.dashboardMainContentExpanded {*/
/*    margin-left: -250px;*/
/*}*/

.dashboardLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.2em;
    color: #666;
}

.dashboardError {
    color: #d32f2f;
    text-align: center;
    padding: 20px;
    font-size: 1.2em;
}

.dashboardNoPatientSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.2em;
    color: #666;
}

@media (max-width: 1200px) {
    .dashboardSidebarContainer {
        width: 100%;
        transform: translateX(-100%);
    }

    .dashboardSidebarHidden {
        transform: translateX(0);
    }

    .dashboardMainContentWrapper {
        margin-right: 0;
    }

    .dashboardMainContent {
        margin-left: 0;
    }

    .dashboardMainContentExpanded {
        margin-left: 0;
    }
}

.dashboardSidebarHidden + .dashboardMainContentWrapper {
    --sidebar-width: 0px;
    margin-left: 0;
}