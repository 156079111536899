.notes-container {
  width: 95%;
  margin: 0 auto;
  padding: 20px;
}

.MuiPaper-root {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.MuiTypography-h5 {
  color: #333333;
  font-weight: 600;
}

.MuiTextField-root {
  margin-bottom: 16px;
}

.MuiButton-contained {
  background-color: #007bff;
  color: white;
  &:hover {
    background-color: #0056b3;
  }
}

.MuiButton-outlined {
  color: #007bff;
  border-color: #007bff;
  &:hover {
    background-color: rgba(0, 123, 255, 0.04);
  }
}

.MuiAccordion-root {
  margin-bottom: 16px;
  border: 1px solid #e0e0e0;
  &:before {
    display: none;
  }
}

.MuiAccordionSummary-root {
  background-color: #f8f9fa;
}

.MuiAccordionDetails-root {
  padding: 16px;
}

.MuiChip-root {
  margin-right: 8px;
  margin-bottom: 8px;
}

.react-markdown {
  font-size: 14px;
  line-height: 1.5;
}

.MuiCircularProgress-root {
  color: #007bff;
  padding: 10px;
}

/* Ensure content starts at the top of the page */
.MuiBox-root {
  padding-top: 0 !important;
}

/* Style for the timestamp chip in Notes History */
.MuiChip-root.MuiChip-outlined {
  background-color: transparent;
  border-color: #007bff;
  color: #007bff;
  font-size: 0.8rem;
}