.topBannerContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.topBanner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 20px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.leftSection {
    display: flex;
    align-items: center;
    z-index: 1;
    flex: 1;
}

.hamburgerMenu {
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 20px;
    color: #333;
}

.logo {
    height: 45px;
    margin-right: 20px;
}

.navSection {
    margin-left: 20px;
}

.navList {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
}

.navItem {
    margin-right: 25px;
    display: flex;
    align-items: center;
}

.navLink {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    padding: 8px;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-bottom 0.3s;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.navLink:hover {
    color: #007bff;
}

.navLinkActive {
    color: #007bff;
    border-bottom: 3px solid #007bff;
}

.navLinkIcon {
    margin-left: 4px;
    font-size: 18px;
}

.rightSection {
    display: flex;
    align-items: center;
    z-index: 1;
    margin-left: auto;
    padding-right: 20px;
    gap: 8px;
}

.userMenuButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.userName {
    margin: 0 8px;
    font-size: 16px;
    color: #333;
}

.centerLogo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.centeredLogo {
    height: 60px;
    width: auto;
}
