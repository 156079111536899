.chatSidebarContainer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chatSidebarPaper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    overflow: hidden;
}

.chatSidebarTitle {
    font-weight: 600;
}

.chatSidebarContent {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    background-color: #f5f5f5;
}

.chatSidebarMessageContainer {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
    align-items: flex-start;
}

.chatSidebarMessageContainerUser {
    justify-content: flex-end;
}


.chatSidebarMessage {
    padding: 8px 12px;
    max-width: calc(100% - 64px);
    border-radius: 18px;
    word-wrap: break-word;
}

.chatSidebarMessageAssistant {
    background-color: white;
    color: black;
    border-top-left-radius: 4px;
    max-width: 75%;

}

.chatSidebarMessageUser {
    background-color: #007bff;
    color: white;
    border-top-right-radius: 4px;
    max-width: 75%;
}

.chatSidebarInputContainer {
    padding: 10px;
    background-color: white;
    border-top: 1px solid #e0e0e0;
    padding-bottom: 30px;
}

.chatSidebarTextField {
    width: 100%;
}

.chatSidebarSamplePrompts {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;

}

.chatSidebarPromptCard {
    margin-bottom: 12px;
}

.chatSidebarPromptCard:hover {
    cursor: pointer;
}

.chatSidebarChip {
    margin: 4px;
    cursor: pointer;
}

.chatSidebarChip:hover {
    background-color: #e3f2fd;
}

.chatSidebarTypingIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
}

.chatSidebarTypingIndicator::after {
    content: '...';
    font-size: 24px;
    line-height: 24px;
    animation: typingAnimation 1s infinite;
}

@keyframes typingAnimation {
    0% {
        content: '.';
    }
    33% {
        content: '..';
    }
    66% {
        content: '...';
    }
    100% {
        content: '.';
    }
}

.chatSidebarPromptChip {
    cursor: pointer;
    transition: background-color 0.3s;
}

.chatSidebarPromptChip:hover {
    background-color: #e3f2fd;
}