html {
    overflow: hidden;
}

.loginPage {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #f4f4f9;
}

.loginMainContent {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/Screenshot%202024-05-16%20at%204.01.28%E2%80%AFPM.png?alt=media&token=12f90e59-8423-4142-9156-7a88a74a5e78');
    background-size: cover;
    background-position: center;
}

.loginContainer {
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.logoContainer {
    text-align: center;
    margin-bottom: 20px;
}

.logo {
    width: 80%;
    height: auto;
}

.formControl {
    font-size: 18px !important;
    height: 50px !important;
    margin-bottom: 15px !important;
}

.loginButton {
    background-color: #007bff !important;
    border: none !important;
    font-weight: bold !important;
    display: block !important;
    width: 100% !important;
    margin-top: 30px !important;
    color: white !important;
    text-transform: none !important;
    font-size: 18px !important;
}

.loginFooter {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 40px;
}

.errorMessage {
    color: #d32f2f;
    text-align: left;
    margin-top: 10px;
}

.loginFooter2 {
    background-color: #D4E5FF;
    padding: 40px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 0.5px solid #007bff;
}

.footerLogo {
    align-self: flex-start;
    margin-left: 10%;
}

.footerLinks {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10%;
    margin-top: 15px;
    align-self: flex-start;
}

.linkedInLink {
    display: flex;
    align-items: center;
    color: #6c757d;
    text-decoration: none;
    border: 1px solid #6c757d;
    padding: 5px 10px;
    border-radius: 4px;
}

.linkedinIcon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    filter: grayscale(100%);
}

.termsPrivacy {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 10px;
    align-items: center;
}

.hyperlink {
    color: #6c757d;
    text-decoration: none;
    filter: grayscale(100%);
}

.hyperlinkBlue {
    color: #007bff;
    text-decoration: none;
}