.R-registration-wrapper {
  height: 100vh;
  overflow-y: auto;
  background: #f4f4f9;
}

.R-registration-page {
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.R-main-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/Screenshot%202024-05-16%20at%204.01.28%E2%80%AFPM.png?alt=media&token=12f90e59-8423-4142-9156-7a88a74a5e78');
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 40px 0;
}

.R-container {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
}

.R-header {
  text-align: center;
  margin-bottom: 20px;
}

.R-logo {
  width: 80%;
  height: auto;
  max-width: 300px;
}

.R-form-control {
  margin-bottom: 15px;
}

.R-trial-info {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid #e9ecef;
}

.R-trial-info h6 {
  color: #2c3e50;
  margin-bottom: 12px;
}

.R-trial-note {
  margin: 16px 0;
  padding: 12px;
  background-color: #fff;
  border-radius: 4px;
}


.R-btn-primary {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  margin-top: 20px;
  padding: 12px;
  text-transform: none;
  font-size: 1.1em;
}

.R-btn-primary:disabled {
  background-color: #cccccc;
}

.R-login-footer {
  margin-top: 20px;
  text-align: center;
}

.R-hyperlink-blue {
  color: #007bff;
  text-decoration: none;
}

.R-hyperlink-blue:hover {
  text-decoration: underline;
}

.R-password-requirements {
  margin-top: 10px;
  font-size: 0.9em;
}

.R-password-requirements ul {
  list-style-type: none;
  padding-left: 0;
}

.R-password-requirements li {
  margin-bottom: 5px;
  color: #777;
}

.R-password-requirements li.met {
  color: #28a745;
}

.R-password-requirements li.met::before {
  content: '✓ ';
  color: #28a745;
}

.R-password-requirements li:not(.met)::before {
  content: '○ ';
  color: #777;
}

.R-form-check {
  margin-bottom: 10px;
}
