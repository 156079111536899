.fill-questionnaire-page {
    .fill-questionnaire {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
    }

    .instructions {
        margin-bottom: 20px;
        font-style: italic;
    }

    .question-item {
        margin-bottom: 20px;
    }

    .question-item textarea {
        width: 100%;
        min-height: 100px;
        margin-top: 10px;
    }

    .character-count {
        text-align: right;
        font-size: 0.8em;
        color: #666;
    }

    .terms-checkbox {
        margin: 20px 0;
    }

    .hyperlink-blue {
        color: blue;
        text-decoration: underline;
    }

    .message {
        color: red;
        margin-bottom: 10px;
    }

    button[type="submit"] {
        background-color: #4CAF50;
        color: white;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
    }

    button[type="submit"]:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }

    .autosave-indicator {
        position: fixed;
        top: 20px;
        right: 20px;
        background-color: #f0f0f0;
        padding: 10px;
        border-radius: 5px;
        font-size: 0.9em;
        color: #333;
        transition: opacity 0.3s ease-in-out;
    }
}