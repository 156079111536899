.sidebarContent {
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-top: 45px;
  background-color: #f5f5f5;
  height: 100%;
  position: fixed;
  width: 220px; /* Adjust this value to match the sidebar width */
  overflow: hidden; /* Prevent scrolling */
}

.addClientBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.addClientBtn:hover {
  background-color: #0056b3;
}

.addClientBtn svg {
  margin-right: 5px;
}

.patientList {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  flex-grow: 1;
}

.patientList li {
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.patientList li:hover {
  background-color: #f0f0f0;
}

.patientList li.selected {
  border: 2px solid #007bff;
  background-color: #e7f3ff;
}

.patientId {
  display: block;
  color: #888;
  font-size: 12px;
  margin-top: 5px;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 20px;
}

.emptyStateIcon {
  font-size: 64px;
  color: #007bff;
  margin-bottom: 10px;
}

.emptyStateTitle {
  margin-bottom: 10px;
  color: #333;
}

.emptyStateText {
  margin-bottom: 20px;
  color: #666;
}